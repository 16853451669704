@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  display: block;

  
}
.app_full{
    display: grid;
    grid-template-columns: 15fr 100fr;
}

.sidenav{
  text-align: left;
  min-width: 240px;
  display: none;
}
.sidenav_open{
  display: block;
}
.toggle_nav{
  position: absolute;
  display: flex;
  flex-shrink: 1;
}

iframe{
  width: 100%;
}
.main-iframe{
  width: 100%;
  height: 100vh;
  border: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


